import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Toast, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/kezia.aac'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import patern from '../assets/img/kristi/3773.svg'
import AOS from 'aos';
import { gambar } from '../params'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#fce7d2ff'
let mainfont = '#AD3243'
let csub = " #E3B62D"
let black = 'rgb(38,38,38)'
export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()
    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      hadir: true,
      err: [],
      submitted: '',
      sesi: 2,
      showgift: false,
      show: false,
      show1: false,
      show2: false
    }
  }
  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
    var countDownDate = new Date("2021","0","9","10").getTime();
    // Update the count down every 1 second
    var x = setInterval(() => {
      // Get today's date and time
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })
    }, 1000);
  }
  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/aac';
    snd.play();
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('top');
      elmnt.scrollIntoView();
    }, 1000)
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
             
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }


  }
  render() {
    let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift, show, show1, show2 } = this.state
    let slide = [
      "	https://i.ibb.co/Jn14Y4X/DSC05847-ok.jpg	",
      "	https://i.ibb.co/b1pJkpL/DSC05949-ok.jpg	",
      "	https://i.ibb.co/vJgbmQ8/DSC06029-ok.jpg	",
      "	https://i.ibb.co/x1F3whY/DSC06210-ok-2.jpg	",
      "	https://i.ibb.co/9gpczL1/DSC06232-ok.jpg	",
      "	https://i.ibb.co/XFDwNvw/DSC06257-ok-1-1.jpg	",
      "	https://i.ibb.co/1RHnnJ6/DSC06271-ok-1.jpg	",
      "	https://i.ibb.co/G2P7SpF/DSC06436-ok-1.jpg	",
      "	https://i.ibb.co/HKfBkFh/DSC06476-ok-2.jpg	",
    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
    })
    let query = this.useQuery().get('name');
    let time = this.useQuery().get('time');
    query = query ? cap(query) : ''

    return (
      <>
        <Helm
          title='Undanganku - Kezia & Bram'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={'https://i.ibb.co/Jr70V7Z/DSC05847-ok.jpg'}
          url='https://undanganku.me/kezia-bram'
        />

        <div id='gold5' style={{
          backgroundColor: cmain,
          backgroundImage: `url('https://svgshare.com/i/RoG.svg')`,
          backgroundSize: '25%'
        }}>
          {
            this.useQuery().get('x') == "x" ? (<Float />) : false
          }
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw',
            backgroundColor: 'transparent'
          }}>
            <Container fluid id='g3-header' className='relative' style={{
              backgroundImage: `url('https://i.ibb.co/NL5DqDZ/Modal.jpg')`
            }}>
              <Item>
                <Col xs={12} md={4} className='m-2 m-md-0 '>
                  <img className='img-fluid w-100 p-2'
                    src={gambar('https://i.ibb.co/gdyyFb1/Logo-Fix.png')} data-aos="fade-left" />
                </Col>
              </Item>
              <Item>
                {
                  <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                    <br /> {query ? query : ''} <br /></h2>
                }</Item>
              <Row className='justify-content-center'>
                <div onClick={() => { this.play() }}

                  className={`col-md-4 button roboto-slab text-center btn ${hide ? 'show' : 'hide'}`}
                  style={{ marginTop: 0, color: 'white' }}>
                  Open Invitation
                  </div>
              </Row>
            </Container>

            <div className={hide ? 'd-none' : 'd-block'}>
              <div id="top" style={{ backgroundColor: 'transparent' }}>
                <Container className="dinny px-3 pt-5 ">
                  <Item>
                    <p className="fs24 text-center">
                      <span className="fs16" style={{ color: '#86835dff' }}>
                        “For love is patient, love is kind. It does not envy, it does not boast, it is not proud. It does not dishonor others, it is not self-seeking, it is not easily angered, it keeps no record of wrongs.” <br /> (1 Corinthians 13:4-5)
                        </span>
                    </p>
                  </Item>
                  <Container id='sectiongold57'>
                    <div className='py-3'>
                     <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                        <iframe className="embed-responsive-item"
                                            src="https://www.youtube.com/embed/txfeLuGP5nQ"></iframe>
                                    </div>
                      <Item>
                        {
                          seconds < 0 || time == "switch" ? (
                            <div className="video-responsive">
                              <iframe width="420" height="315" src="https://www.youtube.com/embed/sgNkCrAhTGc" frameborder="0" allowfullscreen></iframe>
                            </div>
                          ) : (
                              <div data-aos="fade-left" data-aos-duration="1000"
                                className='col-10 col-lg-8 kotak' style={{ backgroundColor: csub }}>
                                <Item>
                                  <div className='item'>
                                    <Item>
                                      <div>
                                        {days}
                                      </div>
                                    </Item>
                                    <Item>
                                      <span>
                                        Days
                                    </span>
                                    </Item>
                                  </div>
                                  <div className='dot'>:</div>
                                  <div className='item'>
                                    <Item>
                                      <div>
                                        {hours}
                                      </div>
                                    </Item>
                                    <Item>
                                      <span>
                                        Hours
                                      </span>
                                    </Item>
                                  </div>
                                  <div className='dot'>:</div>
                                  <div className='item'>
                                    <Item>
                                      <div >
                                        {minutes}
                                      </div>
                                    </Item>
                                    <Item>
                                      <span>
                                        Mins
                                      </span>
                                    </Item>
                                  </div>
                                  <div className='dot' >:</div>
                                  <div className='item'>
                                    <Item>
                                      <div>
                                        {seconds}
                                      </div>
                                    </Item>
                                    <Item>
                                      <span>
                                        Secs
                                      </span>
                                    </Item>
                                  </div>
                                </Item>


                              </div>
                            )
                        }
                      </Item>
                    </div>
                  </Container>
                  <Item>
                    <Col xs={6} sm={2}>
                      <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <p className='text-center p-2 px-4 ' style={{ color: '#86835dff' }}>
                      The Intimate Wedding of Kezia & Bram
                            </p>
                  </Item>
                </Container>
                <Container id='sectiongold55' className="py-5 dinny" >
                  <Item>
                    <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                      <div className='kotak mr-lg-2' style={{ backgroundImage: `url('https://rbmg.fr/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-9.png')` }}>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Kezia
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://iili.io/F6sXyB.jpg', 90,
                              'auto&w=250&h=250&gravity=south')} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3 w-100 text-center" style={{
                            fontSize: '36px', fontFamily: "'Marck Script', cursive",
                            color: mainfont
                          }}>
                            Kezia Anindya Karina
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                            <b>The Daughter of: </b><br />
                            Bpk. K. Hatmadjie Purbo <br />
                        &<br />
                        Ibu Heryulina Pramuhandono
                      </p>
                        </Item>
                        <Item>
                          <Col xs={3} sm={2}
                            onClick={() => { window.open('https://instagram.com/kzanindya') }}>
                            <img src={logoig} className='img-fluid w-100 btn p-0' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                    <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                      <div className='kotak mr-lg-2' style={{ backgroundImage: `url('https://rbmg.fr/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-9.png')` }}>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Bram
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/M7Mz9yt/PP-Bram.jpg',
                              90, 'auto&w=250&h=250&gravity=center')} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3 w-100 text-center" style={{ fontSize: '36px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Abraham Wahyu Kurniawan
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                            <b>The Son of : </b><br />
                            Bpk. Yohanes Joni W. Sukiman

                        <br />
                        &<br />
                        Ibu RR. Agatha Alice Pandansari
                      </p>
                        </Item>
                        <Item>
                          <Col xs={3} sm={2}
                            onClick={() => { window.open('https://instagram.com/bramwk') }}>
                            <img src={logoig} className='img-fluid w-100 btn p-0' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                  </Item>
                </Container>
                <Container fluid className="text-center px-4 dinny" style={{ color: '#86835dff' }} >
                  <Item>
                    <p className="fs16">
                      The wedding will be held on :
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20">
                      <b>
                        Saturday <span className="fs36">09</span> JAN 2021
                      </b>
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20 col-sm-4" style={{ color: '#86835dff' }}>
                      <b className="allura" style={{ fontSize: '46px' }}>Holy Matrimony</b><br />
                      <div style={{ fontSize: '14px' }}>
                        (family only)
                      </div>
                      <div className="py-3">
                        10.00 - finish
                       </div>
                      <br />
                      <span className=" fs16">
                        <b>
                          Santo Leo Agung Church<br />
                        </b>
                    Jl. Manunggal I, Cipinang Melayu, Jakarta Timur, 13620</span>
                    </p>
                    <p className="px-3 d-none d-sm-block" style={{ color: csub, fontSize: '72px' }}>
                      \
                    </p>
                    <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid #86835dff` }}>
                    </div>
                    <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: '#86835dff' }}>
                      <b className="allura" style={{ fontSize: '46px' }}> Intimate Reception</b><br />
                      <div style={{ fontSize: '14px' }}>
                        (family only)
                      </div>
                      <div className="py-3">
                        13:00 - finish</div>
                      <br />
                      <span className=" fs16">
                        <b>
                          Mercure Hotel – Gatot Subroto
                    </b> <br />
                    Jl Gatot Subroto Kav. 1, Kuningan Barat, Jakarta Selatan, 12790
                    </span>
                    </p>
                  </Item>
                  <Item>
                    {/* <Col xs={10} sm={3}
                      style={{
                        border: `2px solid white`,
                        borderRadius: '10px'
                      }}
                      type="button"
                      onClick={() => {
                        window.open("https://www.google.com/maps/dir//Mercure+Jakarta+Gatot+Subroto,+Jl.+Gatot+Subroto+No.Kav.+1,+RT.2%2FRW.2,+Kuningan+Bar.,+Jakarta,+Kota+Jakarta+Selatan,+Daerah+Khusus+Ibukota+Jakarta+12790/@-6.2395284,106.8282317,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2e69f3bce7a4bdf1:0xae2c6b053bbf55c2!2m2!1d106.8304204!2d-6.2395284!3e0")
                      }}
                      className="p-2 mx-sm-2">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                          style={{ width: "10%", height: 'auto' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                          <b>Get Direction</b>
                        </p>
                      </Item>
                    </Col> */}
                    <Col
                      onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=Mmhwc3MxZjlndmE0bWprNTU5YjNscDQyZ3IgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                      xs={10} sm={3}
                      style={{
                        border: `2px solid #86835dff`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-sm-2 mt-3 mt-sm-0 btn ">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                          style={{ width: "10%", height: 'auto' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: '#86835dff' }}>
                          <b>Add to Calendar</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>

                {/* <Container className="text-center dinny">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: csub
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className=" text-center w-100 fs16" style={{color:'white'}}>
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className=" text-center w-100 fs16" style={{color:'white'}}>
                              Gunakan Masker
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className=" text-center w-100 fs16" style={{color:'white'}}>
                              jaga Jarak
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16" style={{color:'white'}}>
                              Cukup Bersalaman tanpa Bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container>
                 */}
                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                  <Item>
                    <div className="butterfly">
                      <h1 style={{ color: '#86835dff', fontStyle: 'bold', fontWeight: 700, fontSize: '72px' }}>
                        The Couple
                        </h1>
                    </div>
                  </Item>
                  <Slider slide={slider} />
                </Container>
                <Container id='sectiongold56'>
                  <div className='pt-3'>

                    <div data-aos={`fade-right`} data-aos-duration="2000">
                      <Item>
                        <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                          <Item>
                            <p className='text-center p-2 px-4' style={{ color: '#86835dff' }}>
                              Therefore what God has joined together, let no one separate <br /> (Mark 10:9-11)
                              </p>
                          </Item>
                        </div>

                      </Item>
                    </div>
                  </div>
                </Container>
                {/* <Container id='sectiongold58' >
                  <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                    <Item>
                      <Col xs={4} lg={2}>
                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                      <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                        <Item>
                          <h1 style={{
                            fontFamily: '"Marck Script", cursive',
                            color: mainfont
                          }}>
                            Send Your Wishes
                            </h1>
                        </Item>
                        <Item>
                          <form className="col-12 w-100">
                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                            <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1" style={{color:mainfont}}>Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"
                                    style={{color:mainfont}}
                                    >Tidak Hadir</label>
                                  </div>

                                </div>
                              </div>
                            </Item>
                            
                            <Item>
                              <Col xs={12} className=''>
                                {
                                  submitted == true ? (
                                    <Alert variant='success' style={{fontSize:'16px'}}>
                                      Pesan anda sudah disampaikan
                                    </Alert>) : (submitted === false ? (
                                      <Alert variant='danger' style={{fontSize:'16px'}}>
                                        {
                                          err.map(val => {
                                            return (
                                              <li>{val}</li>
                                            )
                                          })
                                        }

                                      </Alert>
                                    ) : false)
                                }

                              </Col>
                            </Item>
                            <Item>
                              <button type='button' className='col-6 button rounded'
                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain }} no> Kirim </button>
                            </Item>
                          </form>
                        </Item>
                      </div>
                    </Item>
                  </div>
                </Container>
                 */}
                <Container className="py-3" fluid >
                  <Item>
                    <Col
                      onClick={() => this.setState({ showgift: !showgift })}
                      xs={10} md={4}
                      style={{
                        border: `2px solid #86835dff`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-md-2 mt-3 mt-md-0">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: '#86835dff' }}>
                          <b>Send Gift (click here)</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>
                <Container fluid
                  className={`text-center mt-3 aulia-wedding py-3 px-3 ${!showgift ? 'trsnone' : 'trs'}`} >

                  <Col className="py-3 px-md-5 mx-2 w-100 text-center" style={{ backgroundColor: cmain, borderRadius: '10px', color: '#86835dff' }}>
                    <h1 style={{ color: '#86835dff' }} className="w-100 text-center">
                      Wedding Gift
                      </h1>
                    <p className="text-center w-100" style={{ color: '#86835dff' }}>
                      BCA 217-006-4120  <br />
                            (Kezia Anindya Karina)
                          </p>
                    <Item>
                      <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                        <Toast.Body>Copied!</Toast.Body>
                      </Toast>
                    </Item>
                    <Item>
                      <CopyToClipboard text="217-006-4120">
                        <Col xs={8} className='btn button mb-5 mt-1'
                          onClick={(e) => {
                            this.setState({ show: true })
                          }}>
                          Salin No. Rekening
                          </Col>
                      </CopyToClipboard>
                    </Item>
                    <p className="text-center w-100" style={{ color: '#86835dff' }}>
                      Mandiri 157-00-0075-439-1  <br />
                            (Heryulina)
                          </p>
                    <Item>
                      <Toast onClose={() => this.setState({ show1: false })} show={this.state.show1} delay={3000} autohide >
                        <Toast.Body>Copied!</Toast.Body>
                      </Toast>
                    </Item>

                    <Item>
                      <CopyToClipboard text="157-00-0075-439-1">
                        <Col xs={8} className='btn button mb-5 mt-1'

                          onClick={(e) => {
                            this.setState({ show1: true })
                          }}>
                          Salin No. Rekening
                          </Col>
                      </CopyToClipboard>
                    </Item>
                    <p className="text-center w-100" style={{ color: '#86835dff' }}>
                      OVO 0817-6339-017   <br />
                            (Abraham Wahyu)
                          </p>
                    <Item>
                      <Toast onClose={() => this.setState({ show2: false })} show={this.state.show2} delay={3000} autohide >
                        <Toast.Body>Copied!</Toast.Body>
                      </Toast>
                    </Item>
                    <Item>
                      <CopyToClipboard text="0817-6339-017">
                        <Col xs={8} className='btn button mb-5 mt-1'

                          onClick={(e) => {
                            this.setState({ show2: true })
                          }}>
                          Salin No. Rekening
                          </Col>
                      </CopyToClipboard>
                    </Item>
                    {/* <p className="text-center w-100">
                            Alamat Pengiriman Kado 
                          </p>
                          <p className="text-center w-100 px-3">
                            <b>
                            Jl. Lorem Ipsum
                            </b>
                          </p> */}
                  </Col>


                  <Item>
                    <Col>
                    </Col>
                  </Item>
                </Container>

                <Foot ig={logoig} dark />
              </div>
            </div>
          </div>
        </div>

      </>


    )
  }
}

